@font-face {
  font-family: 'SpyscapeTwo';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c74225c82c155eda781b5ff_spyscapetwo-light-webfont.woff')
    format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'SpyscapeTwo';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c74227882c15509b181b620_spyscapetwo-regular-webfont.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'SpyscapeTwo';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c742266c49a9bf294dae64c_spyscapetwo-medium-webfont.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'SpyscapeTwo';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c742236c49a9b5ab4dae629_spyscapetwo-bold-webfont.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Spyscape';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c0e98898987967dcce9d774_Spyscape-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Spyscape';
  src: url('https://assets.website-files.com/5c0e988989879695ede9d753/5c0e98898987963453e9d78c_Spyscape-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
